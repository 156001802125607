.inputdetails {
    height: 37px;
    min-width: 150px;
    max-width: 250px;
}
.inputprice {
    height: 37px;
    min-width: 100px;
    max-width: 200px;
}

.inputdetails:focus, .inputprice:focus {
    outline: none !important;
    border-color: cornflowerblue;
    /*box-shadow: 0 0 10px #719ECE;*/
}