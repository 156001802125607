
.login,
.image {
    min-height: 100vh;
    margin-left: 0;
}

.bg-image {
    background-image: url('/src/pages/images/background.jpg');
    background-size: cover;
    background-position: center center;
}

body{
    overflow: hidden; /* Hide scrollbars */
}